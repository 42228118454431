import React, { useContext, useRef, useEffect } from "react"
import { motion } from "framer-motion"

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider"

import "./mobile-filter.css"

const MobileFilter = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [open, setOpen] = React.useState(false)

  const wrapperRef = useRef(null)

  // Hook that detects click outside of ref
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          open && setOpen(false)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  const toggleFilter = () => {
    setOpen(!open)
  }

  useOutsideAlerter(wrapperRef)

  const handleSelectFilter = selectedFilter => {
    dispatch({
      type: "SELECT_FILTER",
      payload: {
        selectedFilter: selectedFilter,
      },
    })
    selectedFilter !== "" && setOpen(!open)
  }

  const filterOptionsContainerVariants = {
    open: { height: "auto", width: "100%", overflow: "visible" },
    closed: { height: "0px", width: "100%", overflow: "hidden" },
  }

  const filterOptionVariants = {
    selected: { backgroundColor: state.theme.accent, color: "#000" },
    deselected: { backgroundColor: "#fff", color: "#000" },
  }

  const transition = {
    duration: 0.2,
  }

  return (
    <motion.div id={"filter-container"} ref={wrapperRef}>
      <motion.div
        id={"filter-button"}
        onClick={toggleFilter}
        initial={{ backgroundColor: "#fff", color: "#000" }}
        whileHover={{ backgroundColor: state.theme.accent, color: "#000" }}
        animate={
          open
            ? { backgroundColor: state.theme.accent, color: "#000" }
            : { backgroundColor: "#fff", color: "#000" }
        }
      >
        <h3>FILTER {state.selectedFilter !== "" ? "(1)" : null}</h3>
      </motion.div>
      <motion.div
        id={"filter-options"}
        variants={filterOptionsContainerVariants}
        transition={transition}
        initial={"closed"}
        animate={open ? "open" : "closed"}
      >
        <motion.div
          id={"filter-clear"}
          className={"filter-option"}
          variants={filterOptionVariants}
          initial={"deselected"}
          whileHover={"selected"}
          animate={state.selectedFilter === "" ? "selected" : "deselected"}
          onClick={() => handleSelectFilter("")}
        >
          <h3>SHOW ALL</h3>
        </motion.div>
        <motion.div
          id={"filter-tops"}
          className={"filter-option"}
          variants={filterOptionVariants}
          initial={"deselected"}
          whileHover={"selected"}
          animate={state.selectedFilter === "TOPS" ? "selected" : "deselected"}
          onClick={() => handleSelectFilter("TOPS")}
        >
          <h3>TOPS</h3>
        </motion.div>
        <motion.div
          id={"filter-bottoms"}
          className={"filter-option"}
          variants={filterOptionVariants}
          initial={"deselected"}
          whileHover={"selected"}
          animate={
            state.selectedFilter === "BOTTOMS" ? "selected" : "deselected"
          }
          onClick={() => handleSelectFilter("BOTTOMS")}
        >
          <h3>BOTTOMS</h3>
        </motion.div>
        <motion.div
          id={"filter-objects"}
          className={"filter-option"}
          variants={filterOptionVariants}
          initial={"deselected"}
          whileHover={"selected"}
          animate={
            state.selectedFilter === "OBJECTS" ? "selected" : "deselected"
          }
          onClick={() => handleSelectFilter("OBJECTS")}
        >
          <h3>OBJECTS</h3>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default MobileFilter
