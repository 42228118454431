import React, { useContext, useEffect } from "react"
import { motion } from "framer-motion"

import ProductCard from "../ProductCard/ProductCard"
import Banner from "../Banner/Banner"

import productPathUtil from "../../utils/productPath"

import { containerVariants, itemVariants } from "../../variants/staggerAppear"

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider"

import "./product-list.css"

const ProductList = ({ allProducts, allBanners }) => {
  // Global state and actions
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    dispatch({
      type: "INITIAL_LOAD",
      payload: { initialLoad: true },
    })
  }, [dispatch])

  // Destructure banners
  const banners = allBanners

  // Filter products
  const allFilteredProducts = allProducts.filter(product => {
    switch (state.selectedFilter) {
      case "TOPS":
        return product.node.productCategory.some(r =>
          [
            "T-Shirt",
            "Long Sleeve Shirt",
            "Short Sleeve Shirt",
            "Flannel",
            "Shirt",
            "Sweatshirt",
            "Woolie",
            "Jacket",
            "Coat",
            "Kimono",
          ].includes(r)
        )
      case "BOTTOMS":
        return product.node.productCategory.some(r =>
          ["Jeans", "Pants", "Shorts", "Overalls", "Shoes"].includes(r)
        )
      case "OBJECTS":
        return product.node.productCategory.some(r =>
          [
            "Kimono",
            "Overalls",
            "Shoes",
            "Accessories",
            "Object",
            "Other",
            "other",
          ].includes(r)
        )
      case "":
        return true
      default:
        return true
    }
  })

  // Move highlighted products to the front
  const allFilteredAndHighlightedProducts = [
    ...allFilteredProducts.filter(({ node }) => node.highlightedProduct),
    ...allFilteredProducts.filter(({ node }) => !node.highlightedProduct),
  ]

  // Add in banners
  const allFilteredProductsAndBanners = allFilteredAndHighlightedProducts.reduce(
    (acc, curr, index) => {
      // Add Banner to 6th index
      if (index === 6) {
        return [
          ...acc,
          {
            banner: {
              text: banners[0],
              desktopPosition: "left",
            },
          },
          curr,
        ]
      }

      // Add Banner to 11th index
      if (index === 11) {
        return [
          ...acc,
          {
            banner: {
              text: banners[1],
              desktopPosition: "right",
            },
          },
          curr,
        ]
      }

      // Add Banner to 17th index
      if (index === 17) {
        return [
          ...acc,
          {
            banner: {
              text: banners[2],
              desktopPosition: "left",
            },
          },
          curr,
        ]
      }

      // Add Banner to 22nd index
      if (index === 22) {
        return [
          ...acc,
          {
            banner: {
              text: banners[3],
              desktopPosition: "right",
            },
          },
          curr,
        ]
      }

      // Add Banner to 28th index
      if (index === 28) {
        return [
          ...acc,
          {
            banner: {
              text: banners[4],
              desktopPosition: "left",
            },
          },
          curr,
        ]
      }

      // Add Banner to 33rd index
      if (index === 33) {
        return [
          ...acc,
          {
            banner: {
              text: banners[5],
              desktopPosition: "right",
            },
          },
          curr,
        ]
      }

      // Add Banner to 39th index
      if (index === 39) {
        return [
          ...acc,
          {
            banner: {
              text: banners[6],
              desktopPosition: "left",
            },
          },
          curr,
        ]
      }

      return [...acc, curr]
    },
    []
  )

  return (
    <motion.div
      id={"product-list-container"}
      variants={containerVariants}
      initial={!state.initialLoad ? "initial" : "loaded"}
      animate={"loaded"}
    >
      {allFilteredProductsAndBanners.length <= 0 ? (
        <motion.div id={"product-none-found-container"}>
          <motion.h2 className={"paragraph uppercase"}>
            WE COULDN'T FIND WHAT YOU'RE LOOKING FOR
          </motion.h2>
        </motion.div>
      ) : (
        allFilteredProductsAndBanners.map((product, index) => {
          return product.banner ? (
            <Banner
              text={product.banner.text}
              desktopPosition={product.banner.desktopPosition}
              variants={itemVariants}
              key={index}
            />
          ) : (
            <ProductCard
              key={index}
              productImage={product.sharpImage.node.childImageSharp.fluid}
              productPrice={product.node.productPrice}
              productSale={product.node.productSale}
              href={`/${productPathUtil(product.node.productName)}`}
              variants={itemVariants}
            />
          )
        })
      )}
    </motion.div>
  )
}

export default ProductList
