import React from "react"
import { motion } from "framer-motion"
import { Location } from "@reach/router"
import { Link } from "gatsby"

import Button from "../Button/Button"
import MobileFilter from "../MobileFilter/MobileFilter"

import ImmaterialLogo from "../../svg/immaterial-logo.svg"

import "./mobile-header.css"

const MobileHeader = () => {
  return (
    <>
      <Location>
        {({ location }) => {
          switch (location.pathname) {
            case "/":
              return (
                <>
                  <a
                    href={"https://www.instagram.com/ar/1288776518167419/"}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <motion.img
                      src={ImmaterialLogo}
                      draggable="false"
                      id={"mobile-header-logo"}
                      layout
                      layoutId={"header-logo"}
                    ></motion.img>
                  </a>
                  <motion.div id={"header-buttons-mobile"}>
                    <MobileFilter />
                    <Button
                      text={"ABOUT"}
                      to={"/about"}
                      className={"mobile-about-button"}
                    />
                  </motion.div>
                </>
              )
            case "/about":
              return (
                <>
                  <Link to={"/"}>
                    <motion.img
                      src={ImmaterialLogo}
                      draggable="false"
                      id={"mobile-header-logo"}
                      layout
                      layoutId={"header-logo"}
                    ></motion.img>
                  </Link>
                  <motion.div id={"header-buttons-mobile"}>
                    <MobileFilter />
                    <Button
                      text={"SPECIAL BUTTON"}
                      to={"https://www.instagram.com/ar/1288776518167419/"}
                      className={"special-button"}
                    />
                  </motion.div>
                </>
              )
            // default pages
            default:
              return (
                <>
                  <Link to={"/"}>
                    <motion.img
                      src={ImmaterialLogo}
                      draggable="false"
                      id={"mobile-header-logo"}
                      layout
                      layoutId={"header-logo"}
                    ></motion.img>
                  </Link>
                  <motion.div id={"header-buttons-mobile"}>
                    <MobileFilter />
                    <Button
                      text={"SPECIAL BUTTON"}
                      to={"https://www.instagram.com/ar/1288776518167419/"}
                      className={"special-button"}
                    />
                  </motion.div>
                </>
              )
          }
        }}
      </Location>
    </>
  )
}

export default MobileHeader
