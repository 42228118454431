import React from "react"
import { motion } from "framer-motion"

import "./banner.css"

const Banner = ({ text, desktopPosition, variants }) => {
  return (
    <motion.div
      className={`${desktopPosition}`}
      id={"banner-container"}
      variants={variants}
    >
      <motion.h2 className={"paragraph uppercase"} id={"banner-text"}>
        {text}
      </motion.h2>
    </motion.div>
  )
}

export default Banner
