export const containerVariants = {
  initial: {},
  loaded: {
    transition: {
      type: "tween",
      delay: 0,
      duration: 0.3,
      ease: [0, 0, 1, 1],
      delayChildren: 0.5,
      staggerChildren: 0.08,
    },
  },
}

export const itemVariants = {
  initial: {
    opacity: 0,
    y: 15,
  },
  loaded: {
    opacity: 1,
    y: 0,
  },
}
