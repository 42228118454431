import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { getSalePrice } from "../../utils/getSalePrice"

import "./product-card.css"

const ProductCard = ({
  productPrice,
  productImage,
  href,
  variants,
  productSale,
}) => {
  const isOnSale = productSale > 0
  const salePrice = getSalePrice(productPrice, productSale)

  return (
    <motion.div style={{ height: "100%", width: "100%" }} variants={variants}>
      <Link to={href} id={"product-card-container"}>
        <motion.div id={"product-card-price-container"}>
          <motion.h3
            id={"product-card-price"}
            className={isOnSale ? "sale" : undefined}
          >
            €{productPrice}
          </motion.h3>
          {isOnSale ? (
            <motion.h3 id={"product-card-sale-price"}>€{salePrice}</motion.h3>
          ) : null}
        </motion.div>

        <Img
          fluid={productImage}
          style={{ height: "100%", width: "100%" }}
          imgStyle={{
            objectFit: "contain",
            padding: 10,
          }}
        />
      </Link>
    </motion.div>
  )
}

export default ProductCard
