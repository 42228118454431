import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import MobileHeader from "../components/MobileHeader/MobileHeader"
import ProductList from "../components/ProductList/ProductList"
import DesktopFooter from "../components/DesktopFooter/DesktopFooter"
import MobileFooter from "../components/MobileFooter/MobileFooter"

import { useWindowSize } from "../utils/useWindowSize"

const IndexPage = ({ data }) => {
  // Destructure data
  const allProducts = data.allProductsJson.edges
  const allBanners = data.metadataJson.banners
  const allProductImages = data.allFile.edges

  // // Reduce images to include sharp images
  const allProductsAndImages = allProducts.reduce((prev, curr) => {
    const sharpImage = allProductImages.find(image => {
      return image.node.relativePath === curr.node.productImages[0]
    })

    if (curr.node.productImages === null || undefined) {
      console.log(curr)
    }

    return [
      ...prev,
      {
        ...curr,
        sharpImage: sharpImage,
      },
    ]
  }, [])

  const { width } = useWindowSize()

  return (
    <Layout>
      <div />
      <SEO title="Home" />
      {width > 1278 ? <DesktopHeader /> : <MobileHeader />}
      <ProductList allProducts={allProductsAndImages} allBanners={allBanners} />
      {width > 1278 ? <DesktopFooter /> : <MobileFooter />}
    </Layout>
  )
}

export const data = graphql`
  query AllData {
    metadataJson {
      siteContactEmail
      siteContactPhone
      siteHeroImage
      siteManifesto
      siteTitle
      banners
      aboutPageContent
    }
    allProductsJson {
      edges {
        node {
          productData {
            productProductionCountry
            productMaterial
            productColor
            productAdditionalInfo
            productEra
          }
          highlightedProduct
          productCategory
          productSize
          productShippingPrice
          productPrice
          productName
          productImages
          productDescription
          productSale
        }
      }
    }
    allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          relativePath
        }
      }
    }
  }
`

export default IndexPage
